<!-- 销卡明细 -->
<template>
    <div class="box">
        <div class="nav">销卡明细</div>
        <van-tabs v-model="active" @change="changetab()" background="none" line-width="22" title-active-color="#333333"
            title-inactive-color="#999" color="#1E6FFF">
            <van-tab title="官方发货">
                <div class="drop-shipping">
                    <selectMonth :activemonth="activemonth" :fulltimeL="fulltimeL" :fulltimeR="fulltimeR"
                        :extendNumL="extend" @selcetmonth="selcetmonthFn">
                    </selectMonth>
                    <div class="search">
                        <van-field class="input" type="number" v-model="keyWord"  placeholder="请输入手机号或卡号" />
                        <button @click="goSearch" :style="define?'color:red':''">搜索</button>
                    </div>
                    <div class="header-title">
                        <div class="header-title-item">姓名</div>
                        <div class="header-title-item">电话</div>
                        <div class="header-title-item">
                            <van-dropdown-menu active-color="#1989fa">
                                <van-dropdown-item v-model="value3" :options="option2" @change="changeActiveFn" />
                            </van-dropdown-menu>
                        </div>
                        <div class="header-title-item">
                            <van-dropdown-menu active-color="#1989fa">
                                <van-dropdown-item v-model="value1" :options="option1" @change="changeActiveFn" />
                            </van-dropdown-menu>
                        </div>
                        <div class="header-title-item">详情</div>
                    </div>
                    <div class="table-content">
                        <van-list v-model="vantlistA.loading" :finished="vantlistA.finished" finished-text="没有更多了"
                            @load="onLoad" offset='300'>
                            <div class="table-content-item" v-for="(item, index) in listA" :key="index">
                                <div class="table-content-item-son">
                                    <div class="header-title-item">{{ item.name ? item.name : '' }}
                                    </div>
                                    <div class="header-title-item">{{ item.phone ? item.phone : '' }}
                                    </div>
                                    <div class="header-title-item">{{ item.order_status == 0 ? '未发出' :item.order_status
                                    == 1 ? "已发出" :item.order_status == 2 ? '已签收' : '已取消'
                                    }}</div>
                                    <!-- :style="{ color: item.iccid_state == 0 ? '#999999' : '#1E6FFF' }" -->
                                    <div class="header-title-item">{{
                                    item.iccid_state == 0 ? "未激活" : item.iccid_state== 2 ? '已注册未激活' :
                                    item.iccid_state == 1 ? '已激活' : ''
                                    }}</div>
                                    <div class="header-title-item" style="color:#1E6FFF;" @click="lookInfo(item)">详情
                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </div>
                </div>
            </van-tab>



            <van-tab title="自提发货">
                <div class="selfpickup">
                    <selectMonth :activemonth="activemonth" :fulltimeL="fulltimeL" :fulltimeR="fulltimeR"
                        :extendNumL="extend2" @selcetmonth="selcetmonthFn" :tab="1">
                    </selectMonth>
                    <div class="search">
                        <van-field class="input" v-model="keyWord" type="number" placeholder="请输入卡号" />
                        <button @click="goSearch" :style="define?'color:red':''">搜索</button>
                    </div>

                    <div class="header-title">
                        <div class="header-title-item">
                            <van-dropdown-menu active-color="#1989fa">
                                <van-dropdown-item v-model="value4" :options="option3" @change="changeActiveFn2" />
                            </van-dropdown-menu>
                        </div>
                        <div class="header-title-item">卡片编号</div>
                        <div class="header-title-item">分配人</div>
                        <div class="header-title-item">
                            <van-dropdown-menu active-color="#1989fa">
                                <van-dropdown-item v-model="value2" :options="option1" @change="changeActiveFn2" />
                            </van-dropdown-menu>
                        </div>
                        <div class="header-title-item"></div>
                        <div class="header-title-item">详情</div>
                    </div>

                    <div class="table-content">
                        <van-list v-model="vantlistB.loading" :finished="vantlistB.finished" finished-text="没有更多了"
                            @load="onLoad2" offset='300'>
                            <div class="table-content-item" v-for="(item, index) in listB" :key="index">
                                <div class="table-content-item-son">
                                    <div class="header-title-item"
                                        :style="{ color: item.allocator_status == 1 ? '#999999' : '#1E6FFF' }">
                                        {{item.allocator_status==1?'未分配':"已分配"}}</div>
                                    <div class="header-title-item">{{ item.iccid }}</div>
                                    <div class="header-title-item">
                                        {{item.allocatoruser==null?'':item.allocatoruser.nickname}}</div>
                                    <div class="header-title-item"
                                        :style="{ color: item.is_active == 0 ? '#999999' : '#1E6FFF' }">{{
                                        item.is_active == 0 && item.is_reg == 0 ? "未激活" : item.is_active == 0 &&
                                        item.is_reg
                                        == 1 ? '已注册未激活' : item.is_active == 1 && item.is_reg == 1 ? '已激活' : ''
                                        }}</div>


                                    <div class="header-title-item"></div>
                                    <div class="header-title-item" style="color:#1E6FFF;" @click="lookInfo(item)">详情
                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </div>
                </div>
            </van-tab>
            <van-tab title="代客下单">
                <div class="pickup">
                    <selectMonth :activemonth="activemonth" :fulltimeL="fulltimeL" :fulltimeR="fulltimeR"
                        :extendNumL="extend3" @selcetmonth="selcetmonthFn" :tab="2">
                    </selectMonth>
                    <div class="search">
                        <van-field class="input" v-model="keyWord" placeholder="请输入手机号或卡号" />
                        <button @click="goSearch" :style="define?'color:red':''">搜索</button>
                    </div>
                    <div class="header-title">
                        <div class="header-title-item">姓名</div>
                        <div class="header-title-item">电话</div>
                        <div class="header-title-item">发货状态</div>
                        <div class="header-title-item">激活状态</div>
                        <!-- <div class="header-title-item">
                            <van-dropdown-menu active-color="#1989fa">
                                <van-dropdown-item v-model="value2" :options="option1" @change="changeActiveFn3" />
                            </van-dropdown-menu>
                        </div> -->
                        <div class="header-title-item">详情</div>
                    </div>

                    <div class="table-content">
                        <van-list v-model="vantlistC.loading" :finished="vantlistC.finished" finished-text="没有更多了"
                            @load="onLoad3" offset='300'>
                            <div class="table-content-item" v-for="(item, index) in listC" :key="index">
                                <div class="table-content-item-son">
                                    <div class="header-title-item">{{ item.name }}</div>
                                    <div class="header-title-item">{{ item.phone }}</div>
                                  
                                    <div class="header-title-item">{{ item.order_status == 0 ? '未发出' :item.order_status
                                    == 1 ? "已发出" :item.order_status == 2 ? '已签收' : '已取消'
                                    }}</div>
                                    <div class="header-title-item"
                                        :style="{ color: item.iccid_state == 0 ? '#999999' : '#1E6FFF' }">{{
                                        item.iccid_state == 0 ? "未注册" : item.iccid_state == 1 ? '已激活' : item.iccid_state
                                        == 2? '已注册' : ''
                                        }}</div>

                                    <div class="header-title-item" style="color:#1E6FFF;" @click="lookInfo(item)">详情
                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <van-popup v-model="showMonth" position="bottom" :style="{ height: '60%' }">
            <div v-if="timeActive == 0">
                <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" :min-date="minDate"
                    :max-date="maxDate" @confirm="onConfirm2" @cancel="onCancel">
                </van-datetime-picker>
            </div>
            <div v-else>
                <van-datetime-picker v-model="currentDate2" type="date" title="选择年月日" :min-date="minDate"
                    :max-date="maxDate" @confirm="onConfirm3" @cancel="onCancel">
                </van-datetime-picker>
            </div>
        </van-popup>
        <van-overlay :show="infoShow" @click="infoShow = false">
            <itemInfo v-if="active == 0" :activeInfo="activeInfo" @infoShow="infoShow = false"></itemInfo>
            <itemInfo v-else :activeInfo="activeInfo" @infoShow="infoShow = false" :active="false" :offcardinfo="offcardinfo" :isLoading="isLoading"></itemInfo>
        </van-overlay>
    </div>
</template>

<script>

import { cardList, cardInfo, cardListSelf, cardOrderList } from '@/untils/distubritionIndex.js'
import selectMonth from '@/views/cancelCard/components/selectMonth.vue'
import itemInfo from '@/views/cancelCard/components/info.vue'
export default {
    name: '',
    components: {
        selectMonth, itemInfo
    },
    data() {
        return {
            minDate: new Date(2017, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: new Date(2022, 8, 1),
            currentDate2: new Date(2022, 8, 1),
            infoShow: false, //详情 
            value1: 0,
            value2: 0,

            value4: 0,  //自提发货分配状态
            option3: [
                { text: '分配状态', value: 0 },
                { text: '未分配', value: 1 },
                { text: '已分配', value: 2 },

            ],
            option1: [
                { text: '全部', value: 0 },
                { text: '未激活', value: 1 },
                { text: '已激活', value: 2 },
                { text: '已注册未激活', value: 3 },
            ],
            value3: -1,  //发货状态的值
            option2: [
                { text: '发货状态', value: -1 },
                { text: '未发货', value: 0 },
                { text: '已发出', value: 1 },
                { text: '已签收', value: 2 },
                { text: '已取消', value: 3 },

            ],
            mask: false,
            showMonth: false, // 选择月份的弹窗
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            vantlistA: {
                loading: false,
                finished: false,
            }, //列表A
            vantlistB: {
                loading: false,
                finished: false,
            }, //列表B

            vantlistC: {
                loading: false,
                finished: false,
            }, //列表B
            info: {},//用户基本信息
            active: 0, //tab索引      
            pageA: 0,
            pageB: 0,
            pageC: 0,
            listA: [],//A列表,
            listB: [],//B列表,
            listC: [],//B列表,
            activemonth: 9,//当前月
            activeTime: {},//当前选中月的数据
            timeList: [], //时间列表
            extend: { Activated: 0, notActive: 0, signFor: 0 },  //已激活 未激活 数 
            extend2: { Activated: 0, notActive: 0, signFor: 0 },
            extend3: { Activated: 0, notActive: 0, signFor: 0 },
            mobile: '',//手机号
            activeInfo: {}, //当前查看的详情
            usertype: 1,//type 
            timeActive: 0, //默认选择的时间选择索引 0是左边 
            fulltimeL: '',  //左面开始时间
            fulltimeR: '', //右边结束时间
            startLimit: true, /// 加载限制
            offcardinfo:{},//流量信息
            isLoading:false,//查询流量的loading
            keyWord:'',//关键词
            define:false,//点击确定
        }

    },
    mounted() {
        this.info = JSON.parse(sessionStorage.getItem('UserInfo'))
        if (this.$route.query.mobile) {
            this.mobile = this.$route.query.mobile
            this.usertype = 2
        } else {
            this.mobile = this.info.mobile

        }
        this.getTimeList()
        this.active = this.$route.query.active - 0
    },
    methods: {
        goSearch(){
            this.define = true
            if(this.active == 0){
                this.vantlistA.finished = false
                this.pageA = 0
                this.pageB = 0
                this.pageC = 0
                this.listA = []
                // this.getCardList()
                this.onLoad()
            }else if(this.active == 1){
                this.vantlistB.finished = false
                this.pageB = 0
                this.listB = []
                // this.getCardList2()
                this.onLoad2()
            }else{
                this.vantlistC.finished = false
                this.pageC = 0
                this.listC = []
                // this.getCardList2()
                this.onLoad3()
            }
        },
        //点击确定  左边的选择日期
        onConfirm2() {
            // 点击选择日期以后   然后赋值给 左边时间框
            // 然后对比  看看起始时间是否大于结束时间  
            // 然后关闭弹出层  清空当前的列表数据 
            // 判断当前所在的tab页  发起不同的请求
            let year = this.currentDate.getFullYear()
            let month = this.currentDate.getMonth() + 1
            let day = this.currentDate.getDate()
            month = month < 10 ? "0" + month : month
            day = day < 10 ? "0" + day : day
            let fulltime = year + "-" + month + "-" + day

            var date1 = new Date(fulltime).getTime();
            var date2 = new Date(this.fulltimeR).getTime();
            if (date1 > date2) {
                this.$toast('起始时间不能大于结束时间')
                return false
            }
            this.fulltimeL = fulltime
            this.showMonth = false
            this.pageA = 0
            this.listA = []
            this.listB = []
            if (this.active == 0) {
                this.vantlistA.finished = false
                this.getCardList()
                //这里必须多调用一次 onload 不然第二次会不加载
                this.onLoad()
            } else {
                this.vantlistB.finished = false
                this.getCardList2()
                this.onLoad2()
            }
        },
        //点击确定  右边的选择日期
        onConfirm3() {
            let year = this.currentDate2.getFullYear()
            let month = this.currentDate2.getMonth() + 1
            let day = this.currentDate2.getDate()
            month = month < 10 ? "0" + month : month
            day = day < 10 ? "0" + day : day
            let fulltime = year + "-" + month + "-" + day
            var date1 = new Date(this.fulltimeL).getTime();
            var date2 = new Date(fulltime).getTime();
            if (date1 > date2) {
                this.$toast('结束时间不能小于起始时间')
                return false
            }
            this.fulltimeR = fulltime
            this.showMonth = false
            this.pageA = 0
            this.listA = []
            this.listB = []
            if (this.active == 0) {
                this.vantlistA.finished = false
                this.getCardList()
                this.onLoad()

            } else {
                this.vantlistB.finished = false
                this.getCardList2()
                this.onLoad2()
            }
        },
        async lookInfo(item) {
            let data = {
                from: this.active == 0 ? 1 : this.active == 2 ? 1 : 0,
                id: item.id
            }
            await cardInfo({ data }).then(res => {
                if (res.data.code == 200) {
                    this.activeInfo = res.data.data.data
                    // console.log(this.activeInfo);
                } else {
                    this.$toast(res.data.msg)
                }
            })
            this.getoffinfo(item)
            this.infoShow = true

        },
        getoffinfo(item){
            this.isLoading = true
            let data = {
                iccid:item.iccid
            }
            this.officialState({data}).then(res=>{
                this.offcardinfo = res.data.info
                this.isLoading = false
            })
        },
        //切换tab时触发
        changetab() {
            this.keyWord = ''
            this.extend = { Activated: 0, notActive: 0, signFor: 0 }
            this.extend2 = { Activated: 0, notActive: 0, signFor: 0 }
            this.extend3 = { Activated: 0, notActive: 0, signFor: 0 }
            // console.log('出发了tab');
            if (this.active == 0) {
                this.vantlistA.finished = false
                this.pageA = 0
                this.pageB = 0
                this.pageC = 0
                this.listA = []
                // this.getCardList()
                this.onLoad()
            } else if (this.active == 1) {
                this.vantlistB.finished = false
                this.pageB = 0
                this.listB = []
                // this.getCardList2()
                this.onLoad2()
            } else {
                this.vantlistC.finished = false
                this.pageC = 0
                this.listC = []
                // this.getCardList2()
                this.onLoad3()
            }
        },
        //改变3
        changeActiveFn3() {
            this.vantlistC.finished = false
            this.listC = []
            this.pageC = 0
            // this.getCardList2()
            this.onLoad3()
        },
        //改变2
        changeActiveFn2() {
            this.vantlistB.finished = false
            this.listB = []
            this.pageB = 0
            // this.getCardList2()
            this.onLoad2()
        },
        // 改变1 激活  未激活查询
        changeActiveFn() {
            this.vantlistA.finished = false
            this.listA = []
            this.pageA = 0
            // this.getCardList()
            this.onLoad()
        },
        onCancel() {
            this.showMonth = false
        },

        //选择月份
        selcetmonthFn(num) {
            this.timeActive = num
            this.showMonth = true

        },

        ///加载更多
        onLoad() {
            this.vantlistA.loading = true;
            setTimeout(() => {
                this.getCardList()
                this.define =false   
            }, 1000)
        },   ///加载更多2
        onLoad2() {
            this.vantlistB.loading = true;
            setTimeout(() => {
                this.getCardList2()
                this.define =false   
            }, 1000)
        },
        onLoad3() {
            this.vantlistC.loading = true;
            setTimeout(() => {
                this.getCardList3()
                this.define =false
            }, 1000)
        },
        //获取时间列表
        getTimeList() {
            var time = Date.parse(new Date());
            var t = new Date(time);
            // this.activemonth = month
            let year = t.getFullYear()
            let month = t.getMonth() + 1;
            let day = t.getDate()
            month = month < 10 ? "0" + month : month
            day = day < 10 ? "0" + day : day
            this.fulltimeL = year + '-' + month + '-' + '01'
            this.fulltimeR = year + '-' + month + '-' + day
            // this.getCardList()
            this.onLoad()
        },
        //获取卡片列表
        getCardList() {
            let data = {
                mobile: this.mobile,
                page: ++this.pageA,
                from: this.active == 0 ? 1 : 0,
                start_time: this.fulltimeL + ' ' + '00:00:00',
                end_time: this.fulltimeR + ' ' + '23:59:59',
                is_active: this.value1 == 1 ? '0' : this.value1 == 2 ? '1' : this.value1 == 3 ? '2' : '',  ///0是未激活  1是已激活
                type: this.usertype,  //是否是查看别人的数据 
                is_reg: 1,    //1 是已注册  0 是未注册
                order_status: this.value3,
                keyword:this.keyWord
            }
            if (data.is_active == '') {
                delete (data.is_active)
            }
            if (this.value1 != 3) {
                delete (data.is_reg)
            }

            cardList({ data }).then(res => {
                if (res.data.code != 200) {
                    this.vantlistA.finished = true
                }
                this.vantlistA.loading = false;
                if (res.data.data.data.data.length < 10) {
                    // console.log(res.data.data.data.data.length);
                    this.vantlistA.finished = true
                }
                this.extend = { Activated: 0, notActive: 0, signFor: 0 }
                this.listA = [...this.listA, ...res.data.data.data.data]

                this.extend = res.data.data.extend
                // console.log( this.extend);
                // console.log(  this.listA ,'  this.listA');
            })
        },
        getCardList2() {
            let data = {
                mobile: this.mobile,
                page: ++this.pageB,
                from: this.active == 0 ? 1 : 0,
                // start_time: this.fulltimeL + ' ' + '00:00:00',
                // end_time: this.fulltimeR + ' ' + '23:59:59',
                is_active: this.value2 == 1 ? '0' : this.value2 == 2 ? '1' : this.value2 == 3 ? '0' : '',  ///0是未激活  1是已激活
                type: this.usertype,  //是否是查看别人的数据 
                is_reg: 1,     //1 是已注册  0 是未注册
                allocator_status: this.value4,
                keyword:this.keyWord,
            }
            if (data.is_active == '') {
                delete (data.is_active)
            }
            if (this.value2 != 3) {
                delete (data.is_reg)
            }
            cardListSelf({ data }).then(res => {

                if (res.data.code != 200) {
                    this.vantlistB.finished = true
                }
                this.vantlistB.loading = false;
                if (res.data.data.data.data.length < 10) {
                    this.vantlistB.finished = true
                }
                this.extend2 = { Activated: 0, notActive: 0, signFor: 0 }
                this.listB = [...this.listB, ...res.data.data.data.data]
                this.extend2 = res.data.data.extend
                // console.log(  this.listB ,'  this.listB ');
            })
        },
        getCardList3() {
            let data = {
                invite_phone: this.mobile,

                page: ++this.pageC,
                keyword:this.keyWord,
                // from: this.active == 0 ? 1 : 0,
                // start_time: this.fulltimeL + ' ' + '00:00:00',
                // end_time: this.fulltimeR + ' ' + '23:59:59',
                // is_active: this.value2 == 1 ? '0' : this.value2 == 2 ? '1' : this.value2 == 3 ? '0' : '',  ///0是未激活  1是已激活
                // type: this.usertype,  //是否是查看别人的数据 
                // is_reg: 1, //1 是已注册  0 是未注册
                // allocator_status: this.value4
            }
            if (data.is_active == '') {
                delete (data.is_active)
            }
            if (this.value2 != 3) {
                delete (data.is_reg)
            }
            cardOrderList({ data }).then(res => {

                if (res.data.code != 200) {
                    this.vantlistC.finished = true
                }
                this.vantlistB.loading = false;
                if (res.data.data.data.data.length < 10) {
                    this.vantlistC.finished = true
                }
                this.extend3 = { Activated: 0, notActive: 0, signFor: 0 }
                this.listC = [...this.listC, ...res.data.data.data.data]
                this.extend3 = res.data.data.extend
                // console.log(  this.listB ,'  this.listB ');
            })
        }


    }
};
</script>
<style scoped lang="less">
.box {

    min-height: 100vh;
    background-color: #F8F9FA;

    .search{
            height: 36px;
            width: 100%;
            background-color: #fff;
            display: flex;
            align-items: center;
            .input{
                background: #EFF3F6;
                border-radius: 60px;
                color: #999999;
                height: 30px;
                line-height: 30px;
                margin: auto 20px;
                width: 80%;
                text-indent: 2em;
                display: flex;
                align-items: center;
            }
            ::-webkit-input-placeholder{
                color:#a6a6a6;
                font-size: 12px;
            }
            button{
                color: #333333;
                background: transparent;
            }
        }

    .changetime {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    }

    .changeType {
        width: 100px;
        height: 30px;
        // border-radius: 15px;
        // background-color: #1E6FFF;
        color: #333;
        text-align: center;
        line-height: 30px;
        border-bottom: 1px solid #333;
        // color: #fff;
        // margin-left: 20px;
        // margin-top: 20px;
    }

    .changeTypeActive {
        color: #1E6FFF;
        border-bottom: 1px solid #1E6FFF;
    }


    .wrapper {
        position: absolute;
        left: 49px;
        top: 185px;
        width: 282px;
        height: 470px;
        border-radius: 10px;
        background: linear-gradient(180deg, #D1EDFA 0%, #D1EDFA 0%, #FFFFFF 23%, #FFFFFF 23%);
        z-index: 9999;

        .btncls {

            width: 128px;
            height: 36px;
            margin: 0 auto;
            margin-top: 20px;

            /deep/ .van-button {
                width: 128px;
                height: 36px;
                background-color: #1E6FFF;
            }
        }

        .title {
            font-size: 18px;
            color: #3D3D3D;
            text-align: center;
            margin-top: 33px;

            font-weight: 500;

        }

        .status {
            width: 62px;
            height: 30px;
            border-radius: 6px;
            background: #1E6FFF;
            margin: 20px auto;
            color: #FFFFFF;
            font-size: 14px;
            line-height: 30px;
            text-align: center;

        }

        .text {
            margin: 0 22px 15px;
            // margin-left: 22px;
            // margin-bottom: 15px;
            font-size: 14px;
            color: #999999;
        }
    }

    .nav {
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        // text-align: center;
        justify-content: center;

        img {
            width: 24px;
            height: 24px;
            margin-left: 15px;
            // margin-right: 122px;
        }

        font-size: 16px;
        color: #333333;

        .title {
            margin: 0 auto;
        }
    }

    //代发货题头
    .drop-shipping {
        /deep/ .van-dropdown-menu__bar {
            background-color: #dbe7fd !important;
            box-shadow: none;

            // margin-top: 7px;
            /deep/ .van-ellipsis {
                overflow: visible !important;
                text-overflow: visible !important;

            }

            .van-dropdown-menu__title {
                padding: 0;
            }

            .van-dropdown-menu__title--down::after {
                margin-left: 3px;
            }

            .van-dropdown-menu__title::after {
                right: -7px;
                border-color: transparent transparent #0080ff #0080ff;
            }


        }

        .header-title {
            width: 100%;
            height: 36px;
            padding: 0 20px;
            box-sizing: border-box;
            background: #DBE7FD;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: space-around;

            .header-title-item:nth-child(1),
            .header-title-item:nth-child(5) {
                // height: 100%;
                // line-height: 36px;
                text-align: center;
                width: 60px;
            }

            .header-title-item:nth-child(2),
            .header-title-item:nth-child(3),
            .header-title-item:nth-child(4) {

                //    height: 100%;
                //    line-height: 36px;
                // img {
                //     width: 8px;
                //     height: 5px;
                //     margin-left: 4px;
                // }

                text-align: center;
                width: 60px;

                /deep/ .van-dropdown-menu__item {
                    width: 100%;
                }

                /deep/ .van-dropdown-menu__bar {
                    height: 100%;
                    background-color: none !important;
                }

                /deep/ .van-ellipsis {
                    text-overflow: none !important;
                }

                /deep/ .van-dropdown-menu__bar {
                    background-color: none !important;
                }
            }
        }

        .table-content {

            .table-content-item {
                height: 53px;
                width: 100%;
                box-sizing: border-box;
                padding: 0 20px;
                background-color: #fff;

                .table-content-item-son {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    height: 53px;
                    width: 100%;
                    box-sizing: border-box;
                    border-bottom: 1px solid #F4F7FA;
                    z-index: 999;
                    font-size: 14px;


                    .header-title-item:nth-child(1),
                    .header-title-item:nth-child(5) {
                        text-align: center;
                        width: 60px;
                        // white-space: nowrap; 
                        // text-overflow:ellipsis; 
                        // overflow:hidden; 

                    }

                    .header-title-item:nth-child(2),
                    .header-title-item:nth-child(3),
                    .header-title-item:nth-child(4) {
                        // white-space: nowrap;
                        // text-overflow: ellipsis;
                        // overflow: hidden;
                        // width: 60px;
                        text-align: center;

                    }

                    .header-title-item:nth-child(4) {
                        text-align: left;
                    }

                }
            }

        }
    }

    .pickup {


        /deep/ .van-dropdown-menu__bar {
            background-color: #dbe7fd !important;
            box-shadow: none;

            // margin-top: 7px;
            /deep/ .van-ellipsis {
                overflow: visible !important;
                text-overflow: visible !important;

            }

            .van-dropdown-menu__title {
                padding: 0;
            }

            .van-dropdown-menu__title--down::after {
                margin-left: 3px;
            }

            .van-dropdown-menu__title::after {
                right: -10px;
                border-color: transparent transparent #0080ff #0080ff;
            }


        }


        .header-title {
            width: 100%;
            height: 36px;
            padding: 0 20px;
            box-sizing: border-box;
            background: #DBE7FD;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: space-around;

            .header-title-item:nth-child(1) {

                text-align: center;
                width: 60px;
            }

            .header-title-item:nth-child(2),
            .header-title-item:nth-child(3) {
                text-align: center;
                width: 80px !important;

            }

            .header-title-item:nth-child(4) {
                text-align: center;
                width: 80px;

                /deep/ .van-dropdown-menu__bar {
                    height: 100%;
                    background-color: none !important;
                }

                /deep/ .van-ellipsis {
                    text-overflow: none !important;
                }

                /deep/ .van-dropdown-menu__bar {
                    background-color: none !important;
                }
            }
        }

        .table-content {

            .table-content-item {
                height: 53px;
                width: 100%;
                box-sizing: border-box;
                padding: 0 20px;
                background-color: #fff;

                .table-content-item-son {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    height: 53px;
                    width: 100%;
                    box-sizing: border-box;
                    border-bottom: 1px solid #F4F7FA;
                    z-index: 999;
                    font-size: 14px;


                    .header-title-item:nth-child(1) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-align: left;
                        width: 60px;
                    }

                    .header-title-item:nth-child(2),
                    .header-title-item:nth-child(3) {
                        text-align: center;
                        width: 80px !important;
                    }

                    .header-title-item:nth-child(4) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-align: center;
                        width: 80px;

                    }

                    // .header-title-item:nth-child(4) {
                    //     text-align: center;
                    //     width: 30px;
                    // }
                }
            }

        }
    }

    .selfpickup {


        /deep/ .van-dropdown-menu__bar {
            background-color: #dbe7fd !important;
            box-shadow: none;

            // margin-top: 7px;
            /deep/ .van-ellipsis {
                overflow: visible !important;
                text-overflow: visible !important;

            }

            .van-dropdown-menu__title {
                padding: 0;
            }

            .van-dropdown-menu__title--down::after {
                margin-left: 3px;
            }

            .van-dropdown-menu__title::after {
                right: -10px;
                border-color: transparent transparent #0080ff #0080ff;
            }


        }

        
        .header-title {
            width: 100%;
            height: 36px;
            padding: 0 20px;
            box-sizing: border-box;
            background: #DBE7FD;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: space-around;

            .header-title-item:nth-child(1) {
                text-align: center;
                width: 60px;

                /deep/ .van-dropdown-menu__bar {
                    height: 100%;
                    background-color: none !important;
                }

                /deep/ .van-ellipsis {
                    text-overflow: none !important;
                }

                /deep/ .van-dropdown-menu__bar {
                    background-color: none !important;
                }
            }

            .header-title-item:nth-child(2) {
                width: 120px;
                text-align: center;
            }

            .header-title-item:nth-child(4) {
                text-align: center;
                width: 60px !important;

                /deep/ .van-dropdown-menu__bar {
                    height: 100%;
                    background-color: none !important;
                }

                /deep/ .van-ellipsis {
                    text-overflow: none !important;
                }

                /deep/ .van-dropdown-menu__bar {
                    background-color: none !important;
                }
            }

            .header-title-item:nth-child(3) {
                text-align: center;
                width: 60px;
            }
        }

        .table-content {

            .table-content-item {
                height: 53px;
                width: 100%;
                box-sizing: border-box;
                padding: 0 20px;
                background-color: #fff;

                .table-content-item-son {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    height: 53px;
                    width: 100%;
                    box-sizing: border-box;
                    border-bottom: 1px solid #F4F7FA;
                    z-index: 999;
                    font-size: 14px;


                    .header-title-item:nth-child(1) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        text-align: left;
                        width: 60px;
                        text-align: center;
                    }

                    .header-title-item:nth-child(2) {
                        text-align: center;
                        width: 120px;
                    }

                    .header-title-item:nth-child(3) {
                        width: 60px;
                        text-align: center;
                    }

                    .header-title-item:nth-child(4) {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 60px !important;
                        text-align: left;

                    }

                    .header-title-item:nth-child(4) {
                        text-align: center;
                        width: 30px;
                    }
                }
            }

        }
    }
}
</style>