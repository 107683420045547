<!-- 详情信息 -->
<template>
    <div>
        <div class="wrapper" @click.stop>
            <p class="title">卡片详情</p>
            <div v-if="active==1" class="status">{{ activeInfo.iccid_state == 0 ? "未激活" : activeInfo.iccid_state == 1 ?"已激活":'已注册未激活'  }}</div>
            <div v-if="active==0" class="status">{{ activeInfo.is_active == 0 ? "未激活" : activeInfo.is_active == 1 ?"已激活":'已注册未激活'  }}</div>
            <p class="text">卡号:{{ activeInfo.iccid }}</p>
            <p class="text" v-if="activeInfo.order_no">订单号:{{ activeInfo.order_no ? activeInfo.order_no : '' }}</p>
            <p class="text" v-if="active">下单时间:{{ activeInfo.time }}</p>
            <p class="text" v-if="activeInfo.order_status != null">发货状态:{{activeInfo.order_status == 1 ? '已发货' : activeInfo.order_status == 2 ? '已签收': '未发货'
            }}
            </p>
            <p class="text" v-if="activeInfo.express_no">物流单号:{{ activeInfo.express_no ? activeInfo.express_no : '' }}</p>
            <p class="text" v-if="activeInfo.name">收货人:{{ activeInfo.name ? activeInfo.name : '' }}</p>

            <p class="text" v-if="activeInfo.phone">手机号:{{ activeInfo.phone ? activeInfo.phone : '' }}</p>
            <p class="text" v-if="activeInfo.address">收货地址:{{ activeInfo.address ? activeInfo.address : '' }}</p>
            <p class="text" v-if="!isLoading">剩余流量：{{offcardinfo.surplus?offcardinfo.surplus:''}}{{offcardinfo.surplus_unit?offcardinfo.surplus_unit:''}}</p>
            <p class="text" v-if="isLoading">剩余流量：<span style="color:red">正在查询，请稍后...</span></p>
            <div class="btncls">
                <van-button type="info" round @click="close()">确定</van-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {

        }

    },
    props: {
       activeInfo:{
         type:Object,
        default:()=>{
            return {}
        }
       },
       offcardinfo:{
         type:Object,
        default:()=>{
            return {}
        }
       },
        active:{
        type:Boolean,
        default:true
       },
        isLoading:{
        type:Boolean,
        default:true
       }
     
    },
    mounted() { },
    methods:{
        close(){
            this.$emit('infoShow')
        }
    }
};
</script>
<style scoped lang="less">
.wrapper {
    position: absolute;
    left: 49px;
    top: 185px;
    width: 282px;
    min-height: 200px;
    border-radius: 10px;
    background: linear-gradient(180deg, #D1EDFA 0%, #D1EDFA 0%, #FFFFFF 23%, #FFFFFF 23%);
    z-index: 9999;

    .btncls {

        width: 128px;
        height: 36px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        /deep/ .van-button {
            width: 128px;
            height: 36px;
            background-color: #1E6FFF;
        }
    }

    .title {
        font-size: 18px;
        color: #3D3D3D;
        text-align: center;
        margin-top: 33px;

        font-weight: 500;

    }

    .status {
        min-width: 62px;
        max-width: 100px;
        height: 30px;
        border-radius: 6px;
        background: #1E6FFF;
        margin: 20px auto;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 30px;
        text-align: center;

    }

    .text {
        margin: 0 22px 15px;
        // margin-left: 22px;
        // margin-bottom: 15px;
        font-size: 14px;
        color: #999999;
    }
}
</style>