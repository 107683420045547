<!--  -->
<template>
  <div>

    <div class="table-info-header2" :style="{ justifyContent:tab== 1? 'flex-end':'space-between' }">
      <div class="month" v-if="tab==0">
        <div @click="selectMonth(0)">
          <span class="yue">{{ fulltimeL }}</span>
          <img src="~@/assets/images/distributionIndex/downIcon2.png" alt="">
        </div>

        <span>至</span>
        <div @click="selectMonth(1)">
          <span class="yue">{{ fulltimeR }}</span>
          <img src="~@/assets/images/distributionIndex/downIcon2.png" alt="">
        </div>

      </div>
      <div class="totalMoney">
        <div>
          <p v-if="tab!=2">已激活</p>
          <p v-if="tab!=2">未激活</p>
          <p v-if="tab==0">已签收</p>
        </div>
        <div class="num">
          <p class="numActived" v-if="tab!=2">{{ extendNumL.Activated }}张</p>
          <p class="numNotActived" v-if="tab!=2">{{ extendNumL.notActive }}张</p>
          <p class="numNotActived" v-if="tab==0">{{extendNumL.signFor}}张</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>


export default {
  name: '',
  props: {
    activemonth: {
      type: Number,
      default: 8
    },
    extendNumL: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fulltimeL: {
      type: String,
      default: ''
    },
    fulltimeR: {
      type: String,
      default: ''
    },
    tab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {

    }

  },
  mounted() {
    // console.log(this.fulltimeL);
    // console.log(this.activemonth);
  },
  methods: {
    selectMonth(num) {

      this.$emit('selcetmonth', num)
    }
  }
};
</script>
<style scoped lang="less">
.table-info-header2 {
  width: 100%;
  height: 59px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #1E6FFF;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  color: #ffffff;
  margin-top: 10px;

  .month {
    font-size: 12px;
    color: #ffffff;
    display: flex;

    span {
      font-size: 16px;
      margin-left: 4px;
      // margin-right: 4px;
    }

    .yue {
      font-size: 12px;
      margin-right: 4px;
    }

    img {
      width: 12px;
      height: 8px;
    }
  }

  .totalMoney {
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    display: flex;

    //   flex-direction: column;
    //   justify-content: flex-end;
    .num {
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      align-items: flex-end;

    }

    p {
      color: #A7C5FA;

    }

    .numActived,
    .numNotActived {
      color: #ffffff;
      margin-left: 5px;

    }
  }

}
</style>